import React from "react";
import EndPage from "./components/UI/EndPage.jsx";
import Header from "./components/UI/Header.jsx";

const ContactUs = () => {
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-black via-purple-900 to-black text-white">
      <div className="max-w-6xl mx-auto w-full px-4">
        {/* Header Section */}
        <Header />
      </div>

      {/* Contact Section */}
      <div className="flex flex-col justify-center items-center flex-grow px-4 text-center">
        {/* Page Header */}
        <h1 className="text-4xl font-extrabold mb-6">Contact Us</h1>
        <p className="text-lg text-gray-300 mb-10">
          We'd love to hear from you! Whether you have feedback, comments, or
          need assistance, feel free to reach out.
        </p>

        {/* Google Form Link */}
        <div className="mb-6">
          <a
            href="https://forms.gle/tXaWZMraBi47RZq18" // Replace with your Google Form link
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-purple-600 hover:bg-purple-700 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition"
          >
            Fill Out Our Feedback Form
          </a>
        </div>

        {/* Contact Email */}
        <div className="text-gray-400 text-lg">
          <p>
            Or email us at{" "}
            <a
              href="mailto:support@blendllm.com" // Replace with your email address
              className="text-purple-400 hover:text-purple-300 hover:underline transition"
            >
              contactblendllm@gmail.com
            </a>
          </p>
        </div>
      </div>
      <EndPage />
    </div>
  );
};

export default ContactUs;
