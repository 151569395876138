import React from "react";
import EndPage from "./components/UI/EndPage.jsx";
import Header from "./components/UI/Header.jsx";

const Announcements = () => {
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-black via-purple-900 to-black text-white">
      <div className="max-w-6xl mx-auto w-full px-4">
        <Header />
      </div>

      {/* Announcements Section */}
      <div className="flex flex-col justify-center items-center flex-grow px-4 text-center">
        {/* Page Header */}
        <h1 className="text-4xl font-extrabold mb-6">Announcements</h1>
        <p className="text-lg text-gray-300 mb-10">
          Stay up to date with the latest news and updates from BlendLLM.
        </p>

        {/* Announcements Content */}
        <div className="space-y-8 text-left max-w-4xl">
          {/* Announcement 1 */}
          <div className="p-6 bg-purple-800/50 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-purple-300 mb-2">
              Exciting Updates Coming to BlendLLM This Week! 🚀
            </h2>
            <p className="text-gray-300">
              Hello BlendLLM Community!
            </p>
            <p className="text-gray-300">
              We’re thrilled to announce a set of game-changing updates coming to BlendLLM this week. These new features are designed to make <strong>LLM comparison</strong> more intuitive, seamless, and powerful—whether you’re comparing <strong>Claude vs ChatGPT</strong>, testing <strong>Gemini vs ChatGPT</strong>, or diving deep into the <strong>language capabilities of ChatGPT-4 vs Claude 3.5 Sonnet</strong>.
            </p>
            <p className="text-gray-300 mt-4">
              Here’s what’s new and why you’ll love it:
            </p>

            <ul className="list-decimal list-inside text-gray-300 mt-2 space-y-2">
              <li>
                <strong>Context-Aware Conversations:</strong> Get ready for smarter and more dynamic AI responses! With our new context-aware conversation feature, LLMs can now deliver outputs that are aligned with the flow of your previous inputs. This means:
                <ul className="list-disc list-inside ml-4 mt-1">
                  <li>Blended LLMs interactions for multi-turn conversations.</li>
                  <li>Insights into how models like ChatGPT-4 and Claude 3.5 adapt to ongoing queries.</li>
                  <li>Perfect for exploring real-world use cases like customer service simulations, research, and storytelling.</li>
                </ul>
                Say goodbye to isolated outputs—now you can truly understand how the <strong>language capabilities of ChatGPT-4 compare to Claude 3.5 Sonnet</strong> in a continuous dialogue!
              </li>

              <li>
                <strong>Pre-Loaded Models on the Comparison Page:</strong> No more time wasted on setup—your favorite models are now pre-loaded! The <strong>Compare LLMs page</strong> will now feature:
                <ul className="list-disc list-inside ml-4 mt-1">
                  <li>Quick access to top models like GPT-4, Claude, and Gemini.</li>
                  <li>Pre-loaded configurations to help you dive straight into <strong>LLM comparison</strong> tasks.</li>
                </ul>
                Whether you’re exploring <strong>Claude vs ChatGPT</strong> or testing specific scenarios like <strong>Gemini vs ChatGPT</strong> responses, this update saves you time and makes the process effortless.
              </li>

              <li>
                <strong>Enhanced Workflow for Choosing Models:</strong> We’ve redesigned the way you select models for comparisons. With the improved workflow, you’ll enjoy:
                <ul className="list-disc list-inside ml-4 mt-1">
                  <li>Simplified dropdown menus to select your preferred LLMs.</li>
                  <li>Faster navigation between models like ChatGPT-4, Claude, and Gemini.</li>
                  <li>A cleaner, more intuitive interface for comparing outputs.</li>
                </ul>
                This means you can now test prompts and analyze results like <strong>language capabilities of ChatGPT-4 vs Claude 3.5 Sonnet</strong> with fewer clicks and more focus.
              </li>
            </ul>

            <p className="text-gray-300 mt-4">
              <strong>Why These Updates Matter:</strong> At BlendLLM, we’re committed to making your <strong>LLM comparison</strong> journey smoother and more insightful. These updates aren’t just about new features—they’re about helping you:
            </p>
            <ul className="list-disc list-inside text-gray-300 mt-2 space-y-2">
              <li>Understand subtle differences in <strong>Claude vs ChatGPT</strong> responses.</li>
              <li>Analyze how <strong>Gemini vs ChatGPT</strong> handles different types of queries.</li>
              <li>Leverage the best tools to test the <strong>language capabilities of chatgpt 4o vs claude 3.5 sonnet</strong> for your unique use cases.</li>
            </ul>

            <p className="text-gray-300 mt-4">
              <strong>Try the New Features This Week!</strong> We’re rolling out these updates over the coming days, so don’t miss out. Dive into the <strong>Compare LLMs page</strong>, explore context-aware conversations, and experience the improved workflows.
            </p>
            <p className="text-gray-300 mt-2">
              Curious about how ChatGPT-4 performs in creative tasks vs. Claude 3.5 in logical reasoning? BlendLLM has you covered.
            </p>

            <p className="text-gray-300 mt-4">
              <strong>BlendLLM: The Smartest Way to Compare LLM Outputs</strong> These updates are just the beginning! Whether you’re an AI enthusiast, a student, or a professional looking for precision in <strong>LLM comparison</strong>, BlendLLM is your go-to tool.
            </p>
            <p className="text-gray-300 mt-2">
              Try BlendLLM today and discover how easy it is to:
            </p>
            <ul className="list-disc list-inside text-gray-300 mt-2 space-y-2">
              <li>Compare <strong>Claude vs ChatGPT</strong> side by side.</li>
              <li>Evaluate <strong>Gemini vs ChatGPT</strong> for different tasks.</li>
              <li>Test the <strong>language capabilities of ChatGPT-4 vs Claude 3.5 Sonnet</strong> in your projects.</li>
            </ul>

            <p className="text-gray-300 mt-4">Let us know how these updates help you—we’d love your feedback!</p>
            <p className="text-sm text-gray-400 mt-2">Posted: Dec 29, 2024</p>
          </div>


          <div className="p-6 bg-purple-800/50 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-purple-300 mb-2">
              Upcoming Feature: Context-Aware Chat
            </h2>
            <p className="text-gray-300">
              We're thrilled to announce that soon, users will be able to carry
              forward the context of their previous chat history into new
              prompts. This will enable more dynamic and meaningful interactions
              when comparing LLM outputs.
            </p>
            <p className="text-sm text-gray-400 mt-2">Posted: Nov 27, 2024</p>
          </div>

          {/* Announcement 2 */}
          <div className="p-6 bg-purple-800/50 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-purple-300 mb-2">
              Upcoming Feature: Improved UI for Code Prompts
            </h2>
            <p className="text-gray-300">
              We are excited to unveil a sleek, user-friendly interface designed
              specifically for code-related prompts. This improvement will
              enhance the experience for developers comparing outputs of LLMs
              for coding tasks.
            </p>
            <p className="text-sm text-gray-400 mt-2">Posted: Nov 24, 2024</p>
          </div>
        </div>
      </div>
      <EndPage />
    </div>
  );
};

export default Announcements;
